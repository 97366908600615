<template>
  <div class="outCard">
    <div v-show="$store.state.loadingState">
      <van-loading type="spinner" text-size="20" size="40px" vertical>正在加载中...</van-loading>
    </div>

    <div v-show="$store.state.emptyState">
      <van-empty image="network" description="网络连接异常,请检查更换网络后重试!" />
    </div>
  </div>
</template>

<script>
import { getNameUrl } from "@/network/apiFunc";
export default {
  name: "LoadingView",
  data() {
    return {
      urlList: [],
    };
  },
  mounted() {
    // 1.首先获取链接
    let data = {
      name: "尚境电子",
    };
    getNameUrl(data).then((res) => {
      if (res.success) {
        this.urlList = res.data;

        this.$router.push({
          name: "home",
          params: {
            url: this.urlList.url,
          }
        });

        // localStorage.setItem("appUrl", this.urlList.url)

        console.log(this.urlList.name, this.urlList.url);
      } else {
        this.$notify({ type: "warning", message: "网络连接异常" });
      }
    });
  },
};
</script>

<style>
.outCard {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
